<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import { masterMethods, listPaging, checkPermission } from '@/state/helpers';
import Pagination from '@/components/Pagination/main.vue';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Multiselect from 'vue-multiselect';
import Editor from '@tinymce/tinymce-vue';
import Swal from 'sweetalert2';
import { required } from 'vuelidate/lib/validators';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: '規約詳細一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Editor,
        Multiselect,
        KeyWordTag,
        Pagination,
        Layout,
        LoadingIcon,
        Footer
    },
    data() {
        return {
            listData: [],
            total: 0,
            limit: 10,
            page: 1,
            option: {},
            classification: {},
            is_default: {},
            listDataMasterOption: [],
            listDataMasterClassic: [],
            dataForm: {
                detail: '',
                option: {
                    id: ''
                },
                classification: {
                    id: ''
                },
                is_default: false
            },
            form: {
                detail: ''
            },
            obj: {},
            filterTypes: {
                option: '',
                classification: '',
                is_default: ''
            },
            checkShowFilter: false,
            submitted: false,
            showModal: false,
            perPageList: listPaging,
            shortCode: [
                '《キャンペーン名》',
                '《クライアント名》',
                '《年末年始休み》',
                '《キャンペーン開始》',
                '《キャンペーン終了》',
                '《対象商品名》',
                '《賞品名＿当選者数》',
                '《キャンペーン事務局TEL》',
                '《キャンペーン事務局Mailアドレス》',
                '《事務局開始》',
                '《事務局終了》',
                '《流通エンド》',
                '《単体の合計》',
                '《メーカーエンド》',
                '《単体の対象商品含む》',
                '《単体の対象商品》',
                '《グループの合計》',
                '《グループの対象商品含む》',
                '《グループの対象商品》',
                '《Amazonギフト券》',
                '《EJOICAセレクトギフト》',
                '《QUOカードpay》',
                '《公式Twitterアカウント名》',
                '《公式Instagramアカウント名》',
                '《抽選(当選人数)》',
                '《賞品発送時期》',
                '《CP指定ハッシュタグ》',
                '《応募種別》',
                '《Amazon当選者数》',
                '《EJOICA当選者数》',
                '《QUO当選者数》'
            ],
            configKeyword: {
                arrayTag: [],
                placeholder: '条文・細則',
                isShowLable: true
            },
            isLoading: false
        };
    },
    watch: {
        option: function (value) {
            if (value) {
                this.filterTypes.option = value.id;
            }
        },
        classification: function (value) {
            if (value) {
                this.filterTypes.classification = value.id;
            }
        },
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getList();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getList();
            }
        },
        reload: function () {
            this.getList();
        },
        'configKeyword.arrayTag': function (newOld) {
            if (!this.checkShowFilter) {
                this.listTagFreeWord = newOld;
            } else {
                this.listTagKeyWord = newOld;
            }
        }
    },
    validations: {
        dataForm: {
            classification: {
                id: { required }
            },
            option: {
                id: { required }
            },
            detail: { required }
        }
    },
    mounted() {
        if (!this.checkPermiss('partner.view')) {
            this.$router.push({ path: '/' }).then(() => {
                this.$bvToast.toast(`warning`, {
                    title: `アクセス権限がありません。`,
                    variant: 'warning',
                    toaster: 'b-toaster-top-center',
                    solid: true
                });
            });
        }

        this.getList();
        this.getListMasterOption();
        this.getListMasterClassic();
    },
    computed: {
        from: function () {
            return (this.page - 1) * this.limit + 1;
        },
        to: function () {
            let to = this.page * this.limit;
            return to > this.total ? this.total : to;
        }
    },
    methods: {
        ...masterMethods,
        onEditorInit() {
            this.$nextTick(() => {
                // console.log(editor);
                // const win = editor.getWin();
                // if (win) {
                //     // console.log(win.innerHeight);
                // } else {
                //     // console.error('Window is null');
                // }
            });
        },
        copy(data) {
            this.obj.insertContent(data);
        },
        getListMasterOption() {
            this.listMaster('master_rule_detail.option' + '?limit=999').then((data) => {
                this.listDataMasterOption = data;
            });
        },
        getListMasterClassic() {
            this.listMaster('master_rule_detail.classification' + '?limit=999').then((data) => {
                this.listDataMasterClassic = data;
            });
        },
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.isShowLable = false;
                this.configKeyword.arrayTag = this.listTagKeyWord;
            } else {
                this.configKeyword.placeholder = '条文・細則';
                this.configKeyword.arrayTag = this.listTagFreeWord;
                this.configKeyword.isShowLable = true;
            }
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];

            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) {
                            query.push(`search[${key}]=${encodeURIComponent(value)}`);
                        }
                    }
                }
                if (this.filterTypes) {
                    for (const [key, value] of Object.entries(this.filterTypes)) {
                        if (key && value !== '') {
                            query.push(`filters[${key}]=${encodeURIComponent(value)}`);
                        }
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        async getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            try {
                const response = await this.listRule(query);
                this.listData = response.data;
                this.total = response._metadata.total;
                this.limit = response._metadata.limit * 1;
                const getTypes = response.data.map((item) => item.types);
                if (getTypes.length > 0) {
                    for (let i = 0; i < getTypes.length; i++) {
                        const getNameTypes = this.listDataDepartment.filter((item) => getTypes[i].find((element) => element == item.id));
                        this.listData[i].types = getNameTypes.map((item) => item.value).join(', ');
                    }
                }
                if (!this.listData.length && this.page !== 1) {
                    this.page = 1;
                }
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },

        onChange() {
            this.page = 1;
        },
        search() {
            this.getList();
        },
        clear() {
            this.form = {
                detail: ''
            };
            this.filterTypes = {
                option: '',
                classification: '',
                is_default: ''
            };
            this.option = {};
            this.classification = {};
            this.configKeyword.arrayTag = [];
            this.getList();
        },
        editData(data) {
            this.dataForm = JSON.parse(JSON.stringify(data));
            this.$bvModal.show('modal-register');
            this.onEditorInit();
        },
        deleteData(item) {
            Swal.fire({
                title: '削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteRule2(item).then((data) => {
                        if (data.code == 200) {
                            this.$bvToast.toast(`success`, {
                                title: `削除されました。`,
                                variant: 'success',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                            this.getList();
                        }
                    });
                }
            });
        },
        removeHtmlTags(str) {
            return str.replace(/<\/?[^>]+(>|$)/g, '');
        },
        save() {
            let object = JSON.parse(JSON.stringify(this.dataForm));
            object.detail = this.removeHtmlTags(object.detail);
            if (object.id) {
                this.updateRule2(object).then((data) => {
                    if (data.code == 200) {
                        this.$bvToast.toast(`success`, {
                            title: `更新されました。`,
                            variant: 'success',
                            toaster: 'b-toaster-top-center',
                            solid: true
                        });
                        this.$bvModal.hide('modal-register');
                        this.dataForm = {
                            detail: '',
                            option: {
                                id: ''
                            },
                            classification: {
                                id: ''
                            },
                            is_default: false
                        };
                        this.getList();
                    }
                });
            } else {
                this.createRule2(object).then((data) => {
                    if (data.code == 200) {
                        this.$bvToast.toast(`success`, {
                            title: `登録されました。`,
                            variant: 'success',
                            toaster: 'b-toaster-top-center',
                            solid: true
                        });
                        this.$bvModal.hide('modal-register');
                        this.dataForm = {
                            detail: '',
                            option: {
                                id: ''
                            },
                            classification: {
                                id: ''
                            },
                            is_default: false
                        };
                        this.getList();
                    }
                });
            }
        },
        onModalShown() {
            // Show the editor only after the modal is fully visible
            setTimeout(() => {
                this.showModal = true;
            }, 300);
        },
        onModalHidden() {
            // Show the editor only after the modal is fully visible
            setTimeout(() => {
                this.showModal = false;
            }, 0);
        },
        formSubmit() {
            this.submitted = true;
            this.$v.dataForm.$touch();
            if (this.$v.dataForm.$invalid) {
                return;
            } else {
                this.save();
            }
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">規約詳細検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>
                                    <div v-if="checkShowFilter">
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">条文・細則</label>
                                            <div class="w-100">
                                                <input id="validationCustom05" v-model="form.detail" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">配置</label>
                                            <div class="w-100">
                                                <div style="position: relative; width: 100%">
                                                    <div>
                                                        <multiselect
                                                            :showPointer="false"
                                                            :showNoResults="false"
                                                            selectedLabel=""
                                                            deselectLabel=""
                                                            placeholder=""
                                                            selectLabel=""
                                                            :showNoOptions="false"
                                                            track-by="id"
                                                            label="value"
                                                            v-model="option"
                                                            :options="listDataMasterOption"
                                                        ></multiselect>
                                                        <i
                                                            v-if="option.id"
                                                            @click="
                                                                $event.stopPropagation();
                                                                option = {};
                                                            "
                                                            style="
                                                                cursor: pointer;
                                                                position: absolute;
                                                                right: 15px;
                                                                top: 11px;
                                                                background: #fff;
                                                                z-index: 9;
                                                            "
                                                            class="fa fa-window-close"
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">区分</label>
                                            <div class="w-100">
                                                <div style="position: relative; width: 100%">
                                                    <div>
                                                        <multiselect
                                                            :showPointer="false"
                                                            :showNoResults="false"
                                                            selectedLabel=""
                                                            deselectLabel=""
                                                            placeholder=""
                                                            selectLabel=""
                                                            :showNoOptions="false"
                                                            track-by="id"
                                                            label="value"
                                                            v-model="classification"
                                                            :options="listDataMasterClassic"
                                                        ></multiselect>
                                                        <i
                                                            v-if="classification.id"
                                                            @click="
                                                                $event.stopPropagation();
                                                                classification = {};
                                                            "
                                                            style="
                                                                cursor: pointer;
                                                                position: absolute;
                                                                right: 15px;
                                                                top: 11px;
                                                                background: #fff;
                                                                z-index: 9;
                                                            "
                                                            class="fa fa-window-close"
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">デフォルト有無</label>
                                            <div class="w-100">
                                                <div class="d-flex">
                                                    <div class="custom-control custom-radio mr-4">
                                                        <input
                                                            type="radio"
                                                            v-model="filterTypes.is_default"
                                                            id="両方"
                                                            value=""
                                                            class="custom-control-input"
                                                        />
                                                        <label for="両方" class="custom-control-label">両方</label>
                                                    </div>
                                                    <div class="custom-control custom-radio mr-4">
                                                        <input
                                                            type="radio"
                                                            v-model="filterTypes.is_default"
                                                            id="有"
                                                            value="1"
                                                            class="custom-control-input"
                                                        />
                                                        <label for="有" class="custom-control-label">有</label>
                                                    </div>
                                                    <div class="custom-control custom-radio mr-4">
                                                        <input
                                                            type="radio"
                                                            v-model="filterTypes.is_default"
                                                            id="無"
                                                            value="0"
                                                            class="custom-control-input"
                                                        />
                                                        <label for="無" class="custom-control-label">無</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <h3 class="mb-0 title--screen">
                                規約詳細検索結果 <span>（{{ total }})</span>
                            </h3>
                            <button
                                @click="
                                    $bvModal.show('modal-register');
                                    onEditorInit();
                                    dataForm = {
                                        detail: '',
                                        option: {
                                            id: ''
                                        },
                                        classification: {
                                            id: ''
                                        },
                                        is_default: false
                                    };
                                "
                                class="btn btn-primary"
                            >
                                新規登録
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="pagination__items clearfix pb-3 text-right">
                            <span class="pagination__items__label mr-4"> {{ total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                            <span>表示件数</span>
                            <select v-model="limit" class="pagination__items__select ml-2" @change="onChange">
                                <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                            </select>
                        </div>
                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>条文・細則</th>
                                        <th>配置</th>
                                        <th>区分</th>
                                        <th>デフォルト有無</th>
                                    </tr>
                                </thead>
                                <tbody v-if="listData.length > 0 && !isLoading">
                                    <!--                  <!-<template v-for="(item, k) in listData"> &ndash;&gt;-->
                                    <template>
                                        <tr v-for="(item, k) in listData" :key="item.id">
                                            <td>{{ (page - 1) * limit + k + 1 }}</td>
                                            <td style="max-width: 500px">
                                                <div v-html="item.detail"></div>
                                            </td>
                                            <td>
                                                {{ item.option ? item.option.value : '' }}
                                            </td>
                                            <td>
                                                {{ item.classification ? item.classification.value : '' }}
                                            </td>
                                            <td>
                                                {{ item.is_default ? '有' : '無' }}
                                            </td>
                                            <td align="center">
                                                <a href="javascript:void(0)" class="text-info border-right-1" @click="editData(item)">編集</a>
                                                <a
                                                    v-if="checkPermiss('partner.edit')"
                                                    href="javascript:void(0)"
                                                    class="text-danger"
                                                    @click="deleteData(item)"
                                                    >削除</a
                                                >
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else-if="isLoading">
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <div class="d-flex justify-content-center">
                                                    <LoadingIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.searchEmpty') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <pagination v-if="total && listData.length > 0 && !isLoading" :total="total" :page.sync="page" :per-page="limit"></pagination>
                    </div>
                </div>
            </div>
        </div>

        <Footer />

        <b-modal
            size="xl"
            no-close-on-backdrop
            id="modal-register"
            title="新規登録"
            title-class="font-18"
            scrollable
            hide-footer
            @shown="onModalShown"
            @hidden="onModalHidden"
        >
            <form class="needs-validation" autocomplete="off" @submit.prevent="formSubmit">
                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">配置 <span class="text-danger">*</span></label>
                    <div class="col-sm-6">
                        <div
                            style="position: relative; width: 100%"
                            :class="{
                                'is-invalid': submitted && $v.dataForm.option.id.$error
                            }"
                        >
                            <div>
                                <multiselect
                                    :showPointer="false"
                                    :showNoResults="false"
                                    selectedLabel=""
                                    deselectLabel=""
                                    placeholder=""
                                    selectLabel=""
                                    :showNoOptions="false"
                                    track-by="id"
                                    label="value"
                                    v-model="dataForm.option"
                                    :options="listDataMasterOption"
                                ></multiselect>
                                <i
                                    v-if="dataForm.option && dataForm.option.id"
                                    @click="
                                        $event.stopPropagation();
                                        dataForm.option = {};
                                    "
                                    style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                                    class="fa fa-window-close"
                                ></i>
                            </div>
                        </div>
                        <div v-if="submitted && $v.dataForm.option.id.$error" class="invalid-feedback">
                            <span v-if="!$v.dataForm.option.id.required">{{
                                $t('validateField.required', {
                                    field: '配置'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">区分 <span class="text-danger">*</span></label>
                    <div class="col-sm-6">
                        <div style="position: relative; width: 100%">
                            <div
                                :class="{
                                    'is-invalid': submitted && $v.dataForm.classification.id.$error
                                }"
                            >
                                <multiselect
                                    :showPointer="false"
                                    :showNoResults="false"
                                    selectedLabel=""
                                    deselectLabel=""
                                    placeholder=""
                                    selectLabel=""
                                    :showNoOptions="false"
                                    track-by="id"
                                    label="value"
                                    v-model="dataForm.classification"
                                    :options="listDataMasterClassic"
                                ></multiselect>
                                <i
                                    v-if="dataForm.classification && dataForm.classification.id"
                                    @click="
                                        $event.stopPropagation();
                                        dataForm.classification = {};
                                    "
                                    style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                                    class="fa fa-window-close"
                                ></i>
                            </div>
                            <div v-if="submitted && $v.dataForm.classification.id.$error" class="invalid-feedback">
                                <span v-if="!$v.dataForm.classification.id.required">{{
                                    $t('validateField.required', {
                                        field: '区分'
                                    })
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">デフォルト有無 </label>
                    <div class="col-sm-10">
                        <div class="custom-control custom-checkbox mr-4">
                            <input type="checkbox" v-model="dataForm.is_default" id="is_default1" class="custom-control-input" />
                            <label for="is_default1" class="custom-control-label"></label>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">条文・細則<span class="text-danger">*</span></label>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-sm-8 pr-0">
                                <div
                                    :class="{
                                        'is-invalid': submitted && $v.dataForm.detail.$error
                                    }"
                                >
                                    <Editor
                                        v-if="showModal"
                                        ref="editor"
                                        id="editor2"
                                        SetContent="SetContent"
                                        model-events="change keydown blur focus paste"
                                        v-model="dataForm.detail"
                                        api-key="kzelqkvma9nowaevz1qbr6y90io13sa2l5izqkin42od20k5"
                                        @init="onEditorInit"
                                        :init="{
                                            height: 400,
                                            content_style: 'p { margin: 0 },.tox .tox-statusbar{display: none}',
                                            menubar: true,
                                            forced_root_block: '', // Tắt chế độ tự sinh thẻ <p>
                                            plugins: [
                                                'hr advlist autolink lists link image charmap',
                                                'searchreplace visualblocks code fullscreen',
                                                'print preview anchor insertdatetime media template',
                                                'paste code help wordcount table code lists imagetools code'
                                            ],
                                            toolbar:
                                                ' undo redo | formatselect | bold italic | \
                                          alignleft aligncenter alignright | \
                                          bullist numlist outdent indent | help code\
                                          table tableinsertdialog tablecellprops tableprops | fullscreen | code',
                                            table_toolbar:
                                                'tableprops tabledelete tablemergecells tablesplitcells | tableinsertrowbefore tableinsertrowafter tablerowprops tabledeleterow | tableinsertcolbefore tableinsertcolafter tablecellprops tabledeletecol',

                                            setup: function (editor) {
                                                editor.on('init', function () {
                                                    editor.execCommand('mceToggleEditor'); // Chuyển sang chế độ text
                                                });
                                                obj = editor;
                                            }
                                        }"
                                    />
                                </div>

                                <div v-if="submitted && $v.dataForm.detail.$error" class="invalid-feedback">
                                    <span v-if="!$v.dataForm.detail.required">{{
                                        $t('validateField.required', {
                                            field: '条文・細則'
                                        })
                                    }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4 pr-0">
                                <ul style="list-style: none; padding-left: 0">
                                    <li
                                        @click="copy(item)"
                                        style="border: 1px solid #ddd; display: inline-block; margin-right: 8px; margin-bottom: 8px; cursor: pointer"
                                        v-for="item in shortCode"
                                        :key="item"
                                    >
                                        {{ item }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="d-flex justify-content-center">
                        <div class="text-center flex-fill">
                            <button type="button" class="btn btn-light mr-3" @click="$bvModal.hide('modal-register')">キャンセル</button>
                            <button type="submit" class="btn btn-primary">
                                {{ dataForm.id ? '保存' : '登録' }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
